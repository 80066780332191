import './RegistrationFailed.css'

const RegistrationFailed = () => {
  return (
    <div className="registration-container">
      <h2 className="registration-failed">Registration failed.</h2>
      <p>Invalid or expired token.</p>
    </div>
  )
}

export default RegistrationFailed
