import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

const VerifyEmail = () => {
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const apiUrl = process.env.REACT_APP_BACKEND_API_URL
  const frontendApiUrl = process.env.REACT_APP_FRONTEND_API_URL

  useEffect(() => {
    if (token) {
      window.location.href = `${apiUrl}/api/shopify/verify-email?token=${token}`
    } else {
      window.location.href = `${frontendApiUrl}/verification-failed`
    }
  }, [token, apiUrl, frontendApiUrl])

  return <h2>Redirecting...</h2>
}

export default VerifyEmail
