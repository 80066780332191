import './VerificationSuccess.css'

const VerificationSuccess = () => {
  return (
    <div className="verification-container">
      <h2 className="verification-success">
        Email verified successfully! Your message has been sent.
      </h2>
    </div>
  )
}

export default VerificationSuccess
