import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import './Account.css'

const Account = ({ user, setUser }) => {
  const [orders, setOrders] = useState([])
  const [addresses, setAddresses] = useState([])
  const defaultAddressIdRef = useRef(null)
  const [showAddModal, setShowAddModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [currentAddress, setCurrentAddress] = useState(null)
  const [selectedMainImages, setSelectedMainImages] = useState({})
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [addressToDelete, setAddressToDelete] = useState(null)
  const [showEditNameModal, setShowEditNameModal] = useState(false)
  const [statusMessage, setStatusMessage] = useState(null)
  const [addAddressErrors, setAddAddressErrors] = useState({})
  const [editAddressErrors, setEditAddressErrors] = useState({})
  const [nameEditErrors, setNameEditErrors] = useState({})
  const firstNameRef = useRef(null)
  const lastNameRef = useRef(null)
  const addressInputRefs = useRef({
    street: null,
    city: null,
    state: null,
    zip: null,
    country: null,
    phone: null,
    isDefault: null,
  })
  const editAddressModalRef = useRef({
    street: null,
    city: null,
    state: null,
    zip: null,
    country: null,
    phone: null,
    isDefault: null,
  })
  const apiUrl = process.env.REACT_APP_BACKEND_API_URL
  const navigate = useNavigate()

  const showStatusMessage = (message) => {
    setStatusMessage(message)
  }

  useEffect(() => {
    if (statusMessage) {
      const timer = setTimeout(() => {
        setStatusMessage(null)
      }, 5000)

      return () => clearTimeout(timer)
    }
  }, [statusMessage])

  const fetchAddresses = useCallback(async () => {
    const accessToken = localStorage.getItem('accessToken')
    const response = await fetch(`${apiUrl}/api/shopify/get-addresses`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      credentials: 'include',
    })

    if (response.ok) {
      const data = await response.json()
      setAddresses(data.addresses)

      if (data.defaultAddressId) {
        defaultAddressIdRef.current = data.defaultAddressId
      } else if (data.addresses.length > 0) {
        defaultAddressIdRef.current = data.addresses[0].id
      }
    } else {
      console.error('Failed to fetch addresses')
    }
  }, [apiUrl])

  const fetchOrders = useCallback(async () => {
    const accessToken = localStorage.getItem('accessToken')
    const response = await fetch(`${apiUrl}/api/shopify/get-orders`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      credentials: 'include',
    })

    if (response.ok) {
      const data = await response.json()
      setOrders(data.orders)
    } else {
      console.error('Failed to fetch orders')
    }
  }, [apiUrl])

  const setDefaultAddress = async (addressId) => {
    const accessToken = localStorage.getItem('accessToken')
    const response = await fetch(`${apiUrl}/api/shopify/set-default-address`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ addressId }),
      credentials: 'include',
    })

    if (response.ok) {
      defaultAddressIdRef.current = addressId
    } else {
      const errorData = await response.json()
      showStatusMessage(
        `Failed to update default address: ${
          errorData.error || 'Unknown error'
        }`
      )
    }
  }

  useEffect(() => {
    if (user) {
      fetchOrders()
      fetchAddresses()
    }
  }, [user, fetchAddresses, fetchOrders])

  const deleteAddress = async () => {
    if (!addressToDelete) return

    const accessToken = localStorage.getItem('accessToken')

    const isDeletingDefault = addressToDelete.id === defaultAddressIdRef.current

    const response = await fetch(`${apiUrl}/api/shopify/delete-address`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ addressId: addressToDelete.id }),
      credentials: 'include',
    })

    if (response.ok) {
      await fetchAddresses()

      if (isDeletingDefault) {
        const remainingAddresses = addresses.filter(
          (addr) => addr.id !== addressToDelete.id
        )
        if (remainingAddresses.length > 0) {
          await setDefaultAddress(remainingAddresses[0].id)
        }
      }

      setShowDeleteModal(false)
      setAddressToDelete(null)

      showStatusMessage('Address deleted successfully!')
    } else {
      const errorData = await response.json()
      showStatusMessage(
        `Failed to delete address: ${errorData.error || 'Unknown error'}`
      )
    }
  }

  const handleDeleteClick = (addr) => {
    if (addresses.length === 1) {
      showStatusMessage('You must have at least one address.')
      return
    }

    setAddressToDelete(addr)
    setShowDeleteModal(true)
  }

  const handleThumbnailClick = (orderId, lineItemId, imageSrc) => {
    setSelectedMainImages((prev) => ({
      ...prev,
      [`${orderId}-${lineItemId}`]: imageSrc,
    }))
  }

  const validateNameInput = () => {
    const firstName = firstNameRef.current.value.trim()

    const errors = {}
    if (!firstName) {
      errors.name = 'First name is required.'
    }

    setNameEditErrors(errors)
    return Object.keys(errors).length === 0
  }

  const validateAddressInput = (address, isEdit = false) => {
    const errors = {}
    const refs = isEdit ? editAddressModalRef.current : addressInputRefs.current

    const street = refs.street?.value?.trim()
    const city = refs.city?.value?.trim()
    const state = refs.state?.value?.trim()
    const zip = refs.zip?.value?.trim()
    const country = refs.country?.value?.trim()

    if (!street) {
      errors.street = 'Street address is required'
    }

    if (!city) {
      errors.city = 'City is required'
    }

    if (!state) {
      errors.state = 'State is required'
    }

    if (!zip) {
      errors.zip = 'ZIP code is required'
    } else if (!/^\d{5}(-\d{4})?$/.test(zip)) {
      errors.zip = 'Invalid ZIP code format'
    }

    if (!country) {
      errors.country = 'Country is required'
    }

    if (isEdit) {
      setEditAddressErrors(errors)
    } else {
      setAddAddressErrors(errors)
    }

    return Object.keys(errors).length === 0
  }

  const addAddress = async () => {
    const accessToken = localStorage.getItem('accessToken')

    if (!validateAddressInput()) {
      return
    }

    const newAddress = {
      street: addressInputRefs.current.street?.value?.trim() || '',
      city: addressInputRefs.current.city?.value?.trim() || '',
      state: addressInputRefs.current.state?.value?.trim() || '',
      zip: addressInputRefs.current.zip?.value?.trim() || '',
      country: addressInputRefs.current.country?.value?.trim() || '',
      phone: addressInputRefs.current.phone?.value?.trim() || '',
      isDefault: addressInputRefs.current.isDefault?.checked || false,
    }

    const response = await fetch(`${apiUrl}/api/shopify/add-address`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(newAddress),
      credentials: 'include',
    })

    if (response.ok) {
      const result = await response.json()
      if (result.addressId) {
        if (
          newAddress.isDefault ||
          addresses.length === 0 ||
          !defaultAddressIdRef.current
        ) {
          await setDefaultAddress(result.addressId)
        }
      }
      await fetchAddresses()
      setShowAddModal(false)
      setAddAddressErrors({})
      showStatusMessage('Address added!')
    } else {
      const errorData = await response.json()
      showStatusMessage(
        `Failed to add address: ${errorData.message || 'Unknown error'}`
      )
    }
  }

  const editAddress = async () => {
    const accessToken = localStorage.getItem('accessToken')

    const updatedAddress = {
      addressId: currentAddress.id,
      street: editAddressModalRef.current.street?.value?.trim() || '',
      city: editAddressModalRef.current.city?.value?.trim() || '',
      state: editAddressModalRef.current.state?.value?.trim() || '',
      zip: editAddressModalRef.current.zip?.value?.trim() || '',
      country: editAddressModalRef.current.country?.value?.trim() || '',
      phone: editAddressModalRef.current.phone?.value?.trim() || '',
      isDefault: editAddressModalRef.current.isDefault?.checked || false,
    }

    if (!validateAddressInput(updatedAddress, true)) {
      return
    }

    const response = await fetch(`${apiUrl}/api/shopify/edit-address`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(updatedAddress),
      credentials: 'include',
    })

    if (response.ok) {
      if (updatedAddress.isDefault || !defaultAddressIdRef.current) {
        await setDefaultAddress(currentAddress.id)
      }

      await fetchAddresses()
      setShowEditModal(false)
      setEditAddressErrors({})
      showStatusMessage('Address updated successfully!')
    } else {
      const errorData = await response.json()
      showStatusMessage(
        `Failed to update address: ${errorData.error || 'Unknown error'}`
      )
    }
  }

  const handleEditClick = (addr) => {
    setCurrentAddress({
      ...addr,
      isDefault: addr.id === defaultAddressIdRef.current,
    })

    Object.keys(editAddressModalRef.current).forEach((key) => {
      if (editAddressModalRef.current[key] && key !== 'isDefault') {
        editAddressModalRef.current[key].value = addr[key] || ''
      }
    })

    if (editAddressModalRef.current.isDefault) {
      editAddressModalRef.current.isDefault.checked =
        addr.id === defaultAddressIdRef.current
    }

    setShowEditModal(true)
  }

  const updateName = async () => {
    const accessToken = localStorage.getItem('accessToken')

    if (!validateNameInput()) {
      return
    }

    const firstName = firstNameRef.current.value.trim()
    const lastName = lastNameRef.current.value.trim()

    const errors = {}
    if (!firstName && !lastName) {
      errors.name = 'Please enter at least one name field.'
    }

    const response = await fetch(`${apiUrl}/api/shopify/edit-name`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ firstName, lastName }),
      credentials: 'include',
    })

    if (response.ok) {
      const data = await response.json()
      setUser((prev) => ({
        ...prev,
        customer: {
          ...prev.customer,
          firstName: data.customer.firstName,
          lastName: data.customer.lastName,
        },
      }))
      showStatusMessage('Name updated successfully!')
      setShowEditNameModal(false)
      setNameEditErrors({})
    } else {
      showStatusMessage('Failed to update name.')
    }
  }

  const handleEditNameClick = () => {
    setShowEditNameModal(true)
  }

  const logout = () => {
    localStorage.removeItem('accessToken')
    setUser(null)
    navigate('/login')
  }

  const Modal = ({ show, onClose, title, children }) => {
    if (!show) return null

    return (
      <div className="modal-backdrop">
        <div className="modal-content">
          <div className="modal-header">
            <h2>{title}</h2>
            <button onClick={onClose} className="close-button">
              &times;
            </button>
          </div>
          <div className="modal-body">{children}</div>
        </div>
      </div>
    )
  }

  if (!user) return <p>Loading...</p>

  return (
    <div className="account-component-container">
      {statusMessage && (
        <div className="status-modal">
          <p>{statusMessage}</p>
        </div>
      )}
      <div className="account-container">
        <div className="account-header">
          <h1>Welcome, {user?.customer.firstName}!</h1>
          <button onClick={logout} className="logout-button">
            Logout
          </button>
        </div>
        <h1>Account Details</h1>
        <div className="account-details">
          <div className="name-container">
            <p>
              Name: {user?.customer.firstName} {user?.customer.lastName}
            </p>
            <p>Email: {user?.customer.email}</p>
          </div>
          <div className="edit-name-container">
            <button onClick={handleEditNameClick} className="edit-name-btn">
              Edit
            </button>
          </div>
        </div>

        <Modal
          show={showEditNameModal}
          onClose={() => {
            setShowEditNameModal(false)
            setNameEditErrors({})
          }}
          title="Edit Name"
        >
          <div className="name-edit-form">
            <div className="name-inputs">
              <label>
                <span>First Name:</span>
                <input
                  type="text"
                  defaultValue={user?.customer.firstName || ''}
                  ref={firstNameRef}
                  className={`input-field ${
                    nameEditErrors.name ? 'error' : ''
                  }`}
                />
              </label>
              {nameEditErrors.name && (
                <p className="error-message">{nameEditErrors.name}</p>
              )}
              <label>
                <span>Last Name:</span>
                <input
                  type="text"
                  defaultValue={user?.customer.lastName || ''}
                  ref={lastNameRef}
                  className="input-field"
                />
              </label>
            </div>
            <div className="modal-actions">
              <button onClick={updateName} className="confirm-btn">
                Save Changes
              </button>
              <button
                onClick={() => {
                  setShowEditNameModal(false)
                  setNameEditErrors({})
                }}
                className="cancel-btn"
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>

        <h2>Your Addresses</h2>

        <div className="address-list">
          {addresses.length === 0 ? (
            <p className="no-addresses-orders">No addresses found.</p>
          ) : (
            addresses.map((addr) => (
              <div className="address-item" key={addr.id}>
                <div className="address-details">
                  <p>
                    {addr.street}, {addr.city}, {addr.state}, {addr.zip},{' '}
                    {addr.country}
                    {addr.phone && ` • ${addr.phone}`}
                  </p>
                  {addr.id === defaultAddressIdRef.current ? (
                    <span className="default-label">Default</span>
                  ) : (
                    <span className="not-default-label">Not default</span>
                  )}
                </div>
                <div className="address-actions">
                  <button
                    className="edit-btn"
                    onClick={() => handleEditClick(addr)}
                  >
                    Edit
                  </button>
                  <button
                    className="delete-btn"
                    onClick={() => handleDeleteClick(addr)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))
          )}
        </div>

        <Modal
          className="delete-address-modal"
          show={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          title="Confirm Delete"
        >
          <div className="delete-confirmation">
            <p>Are you sure you want to delete this address?</p>
            {addressToDelete && (
              <div className="address-to-delete">
                <p>
                  {addressToDelete.street}, {addressToDelete.city},{' '}
                  {addressToDelete.state}, {addressToDelete.zip},{' '}
                  {addressToDelete.country}
                </p>
              </div>
            )}
            <div className="modal-actions">
              <button onClick={deleteAddress} className="confirm-delete-btn">
                Yes, Delete
              </button>
              <button
                onClick={() => setShowDeleteModal(false)}
                className="cancel-delete-btn"
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>

        <div className="address-actions">
          <button
            className="add-address-btn"
            onClick={() => setShowAddModal(true)}
          >
            + Add New Address
          </button>
        </div>

        <Modal
          show={showAddModal}
          onClose={() => {
            setShowAddModal(false)
            setAddAddressErrors({})
          }}
          title="Add New Address"
        >
          <div className="address-form">
            <div className="address-input">
              Street:{' '}
              <input
                name="street"
                placeholder="Street"
                ref={(el) => (addressInputRefs.current.street = el)}
                className={`input-field ${
                  addAddressErrors.street ? 'error' : ''
                }`}
                required
              />
              {addAddressErrors.street && (
                <p className="error-message">{addAddressErrors.street}</p>
              )}
            </div>
            <div className="address-input">
              City:{' '}
              <input
                name="city"
                placeholder="City"
                ref={(el) => (addressInputRefs.current.city = el)}
                className={`input-field ${
                  addAddressErrors.city ? 'error' : ''
                }`}
                required
              />
              {addAddressErrors.city && (
                <p className="error-message">{addAddressErrors.city}</p>
              )}
            </div>
            <div className="address-input">
              State:{' '}
              <input
                name="state"
                placeholder="State"
                ref={(el) => (addressInputRefs.current.state = el)}
                className={`input-field ${
                  addAddressErrors.state ? 'error' : ''
                }`}
                required
              />
              {addAddressErrors.state && (
                <p className="error-message">{addAddressErrors.state}</p>
              )}
            </div>
            <div className="address-input">
              ZIP Code:{' '}
              <input
                name="zip"
                placeholder="ZIP Code"
                ref={(el) => (addressInputRefs.current.zip = el)}
                className={`input-field ${addAddressErrors.zip ? 'error' : ''}`}
                required
              />
              {addAddressErrors.zip && (
                <p className="error-message">{addAddressErrors.zip}</p>
              )}
            </div>
            <div className="address-input">
              Country:{' '}
              <input
                name="country"
                placeholder="Country"
                ref={(el) => (addressInputRefs.current.country = el)}
                className={`input-field ${
                  addAddressErrors.country ? 'error' : ''
                }`}
                required
              />
              {addAddressErrors.country && (
                <p className="error-message">{addAddressErrors.country}</p>
              )}
            </div>
            <label className="checkbox-label">
              Set as Default
              <input
                type="checkbox"
                name="isDefault"
                defaultChecked={currentAddress?.isDefault || false}
                ref={(el) => (addressInputRefs.current.isDefault = el)}
              />
            </label>
            <div className="modal-actions">
              <button onClick={addAddress} className="confirm-btn">
                Add Address
              </button>
              <button
                onClick={() => {
                  setShowAddModal(false)
                  setAddAddressErrors({})
                }}
                className="cancel-btn"
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>

        <Modal
          className="edit-address-modal"
          show={showEditModal}
          onClose={() => {
            setShowEditModal(false)
            setEditAddressErrors({})
          }}
          title="Edit Address"
        >
          {currentAddress && (
            <div className="address-form">
              <div className="address-input">
                Street:{' '}
                <input
                  name="street"
                  placeholder="Street"
                  ref={(el) => (editAddressModalRef.current.street = el)}
                  defaultValue={currentAddress?.street || ''}
                  className={`input-field ${
                    editAddressErrors.street ? 'error' : ''
                  }`}
                  required
                />
              </div>
              {editAddressErrors.street && (
                <p className="error-message">{editAddressErrors.street}</p>
              )}
              <div className="address-input">
                City:{' '}
                <input
                  name="city"
                  placeholder="City"
                  ref={(el) => (editAddressModalRef.current.city = el)}
                  defaultValue={currentAddress?.city || ''}
                  className={`input-field ${
                    editAddressErrors.city ? 'error' : ''
                  }`}
                  required
                />
              </div>
              {editAddressErrors.city && (
                <p className="error-message">{editAddressErrors.city}</p>
              )}
              <div className="address-input">
                State:{' '}
                <input
                  name="state"
                  placeholder="State"
                  ref={(el) => (editAddressModalRef.current.state = el)}
                  defaultValue={currentAddress?.state || ''}
                  className={`input-field ${
                    editAddressErrors.state ? 'error' : ''
                  }`}
                  required
                />
              </div>
              {editAddressErrors.state && (
                <p className="error-message">{editAddressErrors.state}</p>
              )}
              <div className="address-input">
                ZIP Code:{' '}
                <input
                  name="zip"
                  placeholder="ZIP Code"
                  ref={(el) => (editAddressModalRef.current.zip = el)}
                  defaultValue={currentAddress?.zip || ''}
                  className={`input-field ${
                    editAddressErrors.zip ? 'error' : ''
                  }`}
                  required
                />
              </div>
              {editAddressErrors.zip && (
                <p className="error-message">{editAddressErrors.zip}</p>
              )}
              <div className="address-input">
                Country:{' '}
                <input
                  name="country"
                  placeholder="Country"
                  ref={(el) => (editAddressModalRef.current.country = el)}
                  defaultValue={currentAddress?.country || ''}
                  className={`input-field ${
                    editAddressErrors.country ? 'error' : ''
                  }`}
                  required
                />
              </div>
              {editAddressErrors.country && (
                <p className="error-message">{editAddressErrors.country}</p>
              )}
              <label className="checkbox-label">
                Set as Default
                <input
                  type="checkbox"
                  name="isDefault"
                  ref={(el) => (editAddressModalRef.current.isDefault = el)}
                  defaultChecked={
                    currentAddress?.id === defaultAddressIdRef.current
                  }
                />
              </label>
              <div className="modal-actions">
                <button onClick={editAddress} className="confirm-btn">
                  Save Changes
                </button>
                <button
                  onClick={() => {
                    setShowEditModal(false)
                    setEditAddressErrors({})
                  }}
                  className="cancel-btn"
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </Modal>

        <h2>Your Orders</h2>

        <div className="order-list">
          {orders.length === 0 ? (
            <p className="no-addresses-orders">No orders found.</p>
          ) : (
            orders.map((order) => (
              <div key={order.id} className="order-item">
                <div className="order-item-image">
                  {order.lineItems.length > 0 && (
                    <>
                      <img
                        src={
                          selectedMainImages[
                            `${order.id}-${order.lineItems[0].id}`
                          ] ||
                          order.lineItems[0].variant?.images[0] ||
                          'placeholder-image-url'
                        }
                        alt={order.lineItems[0].title}
                        className="order-main-image"
                      />
                      <div className="thumbnail-container">
                        {Array.from(
                          new Set(order.lineItems.map((item) => item.title))
                        ).map((uniqueTitle) => {
                          const itemsWithTitle = order.lineItems.filter(
                            (item) =>
                              item.title === uniqueTitle &&
                              item.variant?.images?.length > 0
                          )

                          if (itemsWithTitle.length === 0) return null

                          const allImages = new Set()
                          itemsWithTitle.forEach((item) => {
                            if (item.variant?.images) {
                              item.variant.images.forEach((imgSrc) =>
                                allImages.add(imgSrc)
                              )
                            }
                          })

                          const representativeItem = itemsWithTitle[0]

                          return (
                            <div key={uniqueTitle} className="item-thumbnails">
                              {Array.from(allImages).map((imgSrc, imgIndex) => (
                                <img
                                  key={`${uniqueTitle}-${imgIndex}`}
                                  src={imgSrc}
                                  alt={`${uniqueTitle} - Thumbnail ${
                                    imgIndex + 1
                                  }`}
                                  className={`thumbnail ${
                                    imgSrc ===
                                    selectedMainImages[
                                      `${order.id}-${representativeItem.id}`
                                    ]
                                      ? 'active'
                                      : ''
                                  }`}
                                  onClick={() =>
                                    handleThumbnailClick(
                                      order.id,
                                      representativeItem.id,
                                      imgSrc
                                    )
                                  }
                                />
                              ))}
                            </div>
                          )
                        })}
                      </div>
                    </>
                  )}
                </div>
                <div className="order-item-info">
                  <div className="order-item-details">
                    <div>
                      <h3>Order: {order.name}</h3>
                      <p className="price">
                        Total: ${order.totalPrice} {order.currency}
                      </p>
                      <p>
                        Purchase date:{' '}
                        {new Date(order.createdAt).toLocaleDateString()}
                      </p>
                      <p>Status: {order.fulfillmentStatus}</p>
                    </div>

                    <div className="order-line-items">
                      <h4>Order Items</h4>
                      {order.lineItems.map((item, index) => (
                        <p key={index}>
                          {item.title} x {item.quantity}
                          {item.variant?.title
                            ? ` - ${item.variant.title}`
                            : ''}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  )
}

export default Account
