import React from 'react'
import './ShippingPolicy.css'

const ShippingPolicy = () => {
  return (
    <div className="shipping-policy">
      <div className="shipping-header">
        <h1>Shipping Policy</h1>
      </div>

      <section>
        <h2>1. Shipping Methods and Costs</h2>
        <p>
          We offer the following shipping methods: Economy Shipping: 5-8
          business days and Standard Shipping: 3-4 business days. Shipping costs
          are calculated at checkout based on the delivery address and selected
          shipping method.
        </p>
      </section>

      <section>
        <h2>2. Processing Time</h2>
        <p>
          Orders are processed within 2-5 business days. You will receive an
          email notification once your order has shipped.
        </p>
      </section>

      <section>
        <h2>3. Delivery Time</h2>
        <p>
          Estimated delivery times are provided during checkout. Delivery times
          may vary depending on your location and the shipping method selected.
        </p>
      </section>

      <section>
        <h2>4. International Shipping</h2>
        <p>
          We currently do not offer international shipping. However, this may
          change in the future. Please check back later or contact us for
          updates.
        </p>
      </section>

      <section>
        <h2>5. Order Tracking</h2>
        <p>
          Once your order ships, you will receive a tracking number. You can
          track your package through the carrier's website.
        </p>
      </section>

      <section>
        <h2>6. Shipping Delays</h2>
        <p>
          We are not responsible for delays caused by third-party carriers,
          weather, or other unforeseen circumstances. If your order is delayed,
          please contact us for assistance.
        </p>
      </section>
    </div>
  )
}

export default ShippingPolicy
