import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './ForgotPassword.css'

const ForgotPassword = () => {
  const [email, setEmail] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [status, setStatus] = useState(null)
  const [isEmailValid, setIsEmailValid] = useState(true)
  const apiUrl = process.env.REACT_APP_BACKEND_API_URL

  const validateEmailInput = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setStatus(null)

    if (!validateEmailInput(email)) {
      setIsEmailValid(false)
      return
    }

    setIsSubmitting(true)
    setIsEmailValid(true)

    try {
      const response = await fetch(`${apiUrl}/api/shopify/forgot-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
        credentials: 'include',
      })

      const data = await response.json()

      if (response.ok) {
        setStatus('A reset link has been sent. Check your email!')
        setEmail('')
      } else {
        setStatus(data.error || 'Failed to process your request.')
      }
    } catch (err) {
      setStatus('An error occurred. Please try again later.')
      console.error('Forgot password error:', err)
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    if (status) {
      const timer = setTimeout(() => {
        setStatus(null)
      }, 8000)
      return () => clearTimeout(timer)
    }
  }, [status])

  return (
    <div className="form-container">
      <h2>Reset Your Password</h2>
      <p>Enter your email to receive a password reset link.</p>

      {status && (
        <div className="forgot-status-modal">
          <p>{status}</p>
        </div>
      )}

      <form className="form-group" onSubmit={handleSubmit}>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          placeholder="Email"
          className={isEmailValid ? '' : 'invalid'}
        />
        {!isEmailValid && (
          <p className="error-message">Please enter a valid email address.</p>
        )}

        <button type="submit" className="reset-button" disabled={isSubmitting}>
          {isSubmitting ? 'Sending...' : 'Send Reset Link'}
        </button>
      </form>

      <div className="form-links">
        <Link to="/login">Back to Login</Link>
      </div>
    </div>
  )
}

export default ForgotPassword
