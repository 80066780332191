import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import './Bag.css'

const Bag = ({ items, updateQuantity, removeFromBag, clearBag, user }) => {
  const [removeModalOpen, setRemoveModalOpen] = useState(false)
  const [selectedVariantId, setSelectedVariantId] = useState(null)
  const [selectedSize, setSelectedSize] = useState(null)
  const [mainImages, setMainImages] = useState({})
  const [agreeToTerms, setAgreeToTerms] = useState(false)
  const [errors, setErrors] = useState({})
  const apiUrl = process.env.REACT_APP_BACKEND_API_URL
  const navigate = useNavigate()

  useEffect(() => {
    const initialImages = {}
    items.forEach((item) => {
      item.variants.forEach((variant) => {
        initialImages[variant.id] = item.images?.[0]?.src || ''
      })
    })
    setMainImages(initialImages)
  }, [items])

  const totalPrice = items.reduce((sum, item) => {
    const variant = item.variants.find((v) =>
      v.selectedOptions.some((o) => o.name === 'Size' && o.value === item.size)
    )
    const price = variant?.price ? parseFloat(variant.price) : 0
    return sum + price * item.quantity
  }, 0)

  const proceedToCheckout = async () => {
    if (!user && !agreeToTerms) {
      setErrors({
        terms: 'Please agree to the terms and conditions before proceeding.',
      })
      return
    }

    setErrors({})

    try {
      const lineItems = items.map((item) => ({
        variant_id: item.variantId,
        quantity: item.quantity,
      }))

      const token = localStorage.getItem('accessToken')

      const response = await fetch(`${apiUrl}/api/shopify/checkout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token ? `Bearer ${token}` : undefined,
        },
        body: JSON.stringify({
          lineItems,
          customerAccessToken: token,
        }),
        credentials: 'include',
      })

      if (response.ok) {
        const data = await response.json()
        clearBag()
        window.location.href = data.checkoutUrl
      } else {
        console.error('Failed to create checkout session')
        alert('Failed to proceed to checkout. Please try again.')
      }
    } catch (error) {
      console.error('Error during checkout:', error)
      alert('An error occurred. Please try again later.')
    }
  }

  const handleTermsChange = () => {
    setAgreeToTerms(!agreeToTerms)
    if (!agreeToTerms) {
      setErrors({ ...errors, terms: '' })
    }
  }

  const handleQuantityIncrease = (item) => {
    updateQuantity(item.variantId, item.size, item.quantity + 1)
  }

  const handleQuantityDecrease = (item) => {
    if (item.quantity > 1) {
      updateQuantity(item.variantId, item.size, item.quantity - 1)
    }
  }

  const handleRemoveClick = (item) => {
    setSelectedVariantId(item.variantId)
    setSelectedSize(item.size)
    setRemoveModalOpen(true)
  }

  const confirmRemoval = () => {
    removeFromBag(selectedVariantId, selectedSize)
    setRemoveModalOpen(false)

    if (items.length === 1) {
      navigate('/')
    }
  }

  const cancelRemoval = () => {
    setRemoveModalOpen(false)
    setSelectedVariantId(null)
    setSelectedSize(null)
  }

  const handleThumbnailClick = (imageSrc, index) => {
    setMainImages((prev) => ({
      ...prev,
      [index]: imageSrc,
    }))
  }

  const getOptionValue = (item, optionName) => {
    const variant = item.variants?.[0]
    const option = variant?.selectedOptions?.find(
      (opt) => opt.name.toLowerCase() === optionName.toLowerCase()
    )
    return option ? option.value : 'N/A'
  }

  return (
    <div className="bag-container">
      {items.length === 0 ? (
        <h2 className="empty-bag-message">No items in bag</h2>
      ) : (
        <div className="bag-items-container">
          <div className="bag-items">
            {items.map((item) => {
              const variant = item.variants.find((v) =>
                v.selectedOptions.some(
                  (o) => o.name === 'Size' && o.value === item.size
                )
              )
              const variantId = variant?.id
              const productImages = item.images || []
              const mainProductImage =
                mainImages[variantId] || productImages[0]?.url

              return (
                <div key={`${variantId}-${item.size}`} className="bag-item">
                  <div className="bag-item-image">
                    <img
                      src={mainProductImage}
                      alt={item.title}
                      className="bag-main-image"
                    />
                    <div className="thumbnail-container">
                      {productImages.map((image, idx) => (
                        <img
                          key={idx}
                          src={image.url}
                          alt={`Thumbnail ${idx + 1}`}
                          className={`thumbnail ${
                            image.url === mainImages[variantId] ? 'active' : ''
                          }`}
                          onClick={() =>
                            handleThumbnailClick(image.url, variantId)
                          }
                        />
                      ))}
                    </div>
                  </div>
                  <div className="bag-item-info">
                    <div className="bag-item-details">
                      <h3>{item.title}</h3>
                      <p className="price">
                        Price: ${item.variants?.[0]?.price || 'N/A'}
                      </p>
                      <p>
                        Quantity:{' '}
                        <span className="value-update">{item.quantity}</span>
                      </p>
                      <p>Size: {item.size || getOptionValue(item, 'Size')}</p>

                      <p className="product-color">
                        Color: {getOptionValue(item, 'Color')}
                      </p>
                    </div>
                    <div className="quantity-buttons">
                      <button
                        className="quantity-button"
                        onClick={() => handleQuantityDecrease(item)}
                        disabled={item.quantity === 1}
                      >
                        -
                      </button>

                      <button
                        className="quantity-button"
                        onClick={() => handleQuantityIncrease(item)}
                        disabled={
                          item.quantity >=
                          (item.variants?.find((variant) =>
                            variant.selectedOptions.some(
                              (option) =>
                                option.name === 'Size' &&
                                option.value === item.size
                            )
                          ).inventoryQuantity || 10)
                        }
                      >
                        +
                      </button>

                      <button
                        onClick={() => handleRemoveClick(item)}
                        className="remove-button"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          {!user && (
            <div className="terms-checkbox">
              <label className="bag-checkbox-container">
                <span className="checkbox-star">*</span>
                <span>
                  I agree to the{' '}
                  <Link to="/terms-conditions">terms and conditions</Link>
                </span>
                <input
                  type="checkbox"
                  id="agree-terms"
                  checked={agreeToTerms}
                  onChange={handleTermsChange}
                  className="bag-checkbox-input"
                />
              </label>
              {errors.terms && <p className="error">{errors.terms}</p>}
            </div>
          )}
          <div className="checkout-note">
            <p className="star">* </p>
            <span>
              Please note that the final price does not include shipping and
              handling fees.
            </span>
          </div>
          <div className="checkout-container">
            <div className="total-price">
              <p>Total: </p>
              <span key={totalPrice} className="value-update">
                <p className="price">${totalPrice.toFixed(2)}</p>
              </span>
            </div>
            <div className="checkout-button-container">
              <button onClick={proceedToCheckout} className="checkout-button">
                Proceed to Checkout
              </button>
            </div>
          </div>
        </div>
      )}

      {removeModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <p>Are you sure you want to remove this product from your bag?</p>
            <div className="modal-buttons">
              <button className="yes-button" onClick={confirmRemoval}>
                Yes
              </button>
              <button onClick={cancelRemoval}>No</button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Bag
