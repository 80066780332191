import React, { useState, useEffect } from 'react'
import './Contact.css'

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  })
  const [errors, setErrors] = useState({})
  const [status, setStatus] = useState(null)
  const apiUrl = process.env.REACT_APP_BACKEND_API_URL

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const validateName = (name) => {
    const nameRegex = /^[A-Za-z ]{2,50}$/
    return nameRegex.test(name)
  }

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const validateMessage = (message) => {
    return message.trim().length > 0
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    let newErrors = {}

    if (!validateName(formData.name)) {
      newErrors.name =
        'Name should only contain letters and spaces and be at least 2 characters long.'
    }
    if (!validateEmail(formData.email)) {
      newErrors.email = 'Please enter a valid email address.'
    }
    if (!validateMessage(formData.message)) {
      newErrors.message = 'Message cannot be empty.'
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)
      return
    }

    setStatus('Sending verification email...')

    try {
      const response = await fetch(`${apiUrl}/api/shopify/contact`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
        credentials: 'include',
      })

      if (response.ok) {
        setStatus(
          'A verification email has been sent. Please check your inbox!'
        )
        setFormData({ name: '', email: '', message: '' })
      } else {
        setStatus('Failed to send verification email. Try again.')
      }
    } catch (error) {
      console.error('Error:', error)
      setStatus('An error occurred. Please try again.')
    }
  }

  useEffect(() => {
    if (status) {
      const timer = setTimeout(() => {
        setStatus(null)
      }, 8000)

      return () => clearTimeout(timer)
    }
  }, [status])

  return (
    <div className="contact-form-container">
      <h2 className="form-header">Contact Us</h2>
      {status && (
        <div className="contact-status-modal">
          <p>{status}</p>
        </div>
      )}
      <form onSubmit={handleSubmit} className="contact-form">
        <input
          type="text"
          name="name"
          placeholder="Your Name"
          value={formData.name}
          onChange={handleChange}
          className={errors.name ? 'input-field error-input' : 'input-field'}
        />
        {errors.name && <p className="error-message">{errors.name}</p>}

        <input
          type="email"
          name="email"
          placeholder="Your Email"
          value={formData.email}
          onChange={handleChange}
          className={errors.email ? 'input-field error-input' : 'input-field'}
        />
        {errors.email && <p className="error-message">{errors.email}</p>}

        <textarea
          name="message"
          placeholder="Your Message"
          value={formData.message}
          onChange={handleChange}
          className={
            errors.message ? 'textarea-field error-input' : 'textarea-field'
          }
        />
        {errors.message && <p className="error-message">{errors.message}</p>}

        <button type="submit" className="submit-button">
          Verify & Send
        </button>
      </form>
    </div>
  )
}

export default ContactForm
