import './RegistrationPending.css'

const RegistrationPending = () => {
  return (
    <div className="registration-pending-container">
      <h2 className="registration-pending-message">
        A verification email has been sent.
      </h2>
      <p> Please check your inbox to complete registration.</p>
    </div>
  )
}

export default RegistrationPending
