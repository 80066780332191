import React from 'react'
import './ReturnPolicy.css'

const ReturnPolicy = () => {
  return (
    <div className="return-policy">
      <div className="return-header">
        <h1>Return Policy</h1>
      </div>

      <section>
        <h2>1. Returns</h2>
        <p>
          You may return unused and unworn items within 30 days of receipt
          unless they arrive damaged or defective.
        </p>
      </section>

      <section>
        <h2>2. Process</h2>
        <p>
          To initiate a return, please visit our{' '}
          <a href="/contact">Contact Page</a> with your order number and reason
          for return. You are responsible for return shipping on all other
          returns.
        </p>
      </section>

      <section>
        <h2>3. Refunds</h2>
        <p>
          Once we receive the returned item, we will inspect it and notify you
          of the approval or rejection of your refund. Refunds will be processed
          to the original payment method. Processing times may vary based on the
          payment provider.
        </p>
      </section>

      <section>
        <h2>4. Exchanges</h2>
        <p>
          We do not offer exchanges at this time. Please return the item and
          place a new order if needed.
        </p>
      </section>
    </div>
  )
}

export default ReturnPolicy
