import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import './Login.css'

const Login = ({ setUser }) => {
  const [formData, setFormData] = useState({ email: '', password: '' })
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const apiUrl = process.env.REACT_APP_BACKEND_API_URL
  const navigate = useNavigate()

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    try {
      const response = await fetch(`${apiUrl}/api/shopify/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
        credentials: 'include',
      })

      const data = await response.json()

      if (response.ok) {
        const { accessToken, expiresAt } = data
        localStorage.setItem('accessToken', accessToken)
        localStorage.setItem('tokenExpiry', expiresAt)

        setUser(data.user || data)
        navigate('/account')
      } else {
        setError(data.error || 'Failed to log in. Please try again.')
      }
    } catch (error) {
      setError('An error occurred. Please try again.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="login-container">
      <h2 className="form-header">Login</h2>
      <form onSubmit={handleSubmit} className="login-form">
        <input
          name="email"
          placeholder="Email"
          type="email"
          onChange={handleChange}
          className={error ? 'error-input' : ''}
          required
        />
        <input
          name="password"
          placeholder="Password"
          type="password"
          onChange={handleChange}
          className={error ? 'error-input' : ''}
          required
        />
        {error && <p className="error">{error}</p>}
        <button type="submit">{loading ? 'Logging in...' : 'Login'}</button>
      </form>
      <div className="login-links">
        <p>
          Don't have an account? <Link to="/register">Register here</Link>
        </p>
        <p>
          Forgot your password? <Link to="/forgot-password">Reset it</Link>
        </p>
      </div>
    </div>
  )
}

export default Login
