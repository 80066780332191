import './VerificationFailed.css'

const VerificationFailed = () => {
  return (
    <div className="verification-container">
      <h2 className="verification-failed">
        Verification failed. Invalid or expired token.
      </h2>
    </div>
  )
}

export default VerificationFailed
