import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import ProductList from './components/ProductList'
import Bag from './components/Bag'
import Nav from './components/Nav'
import Footer from './components/Footer'
import Contact from './components/Contact'
import VerifyEmail from './components/VerifyEmail'
import VerificationFailed from './components/VerificationFailed'
import VerificationSuccess from './components/VerificationSuccess'
import TermsAndConditions from './components/TermsAndConditions'
import ReturnPolicy from './components/ReturnPolicy'
import ShippingPolicy from './components/ShippingPolicy'
import Register from './components/Register'
import Login from './components/Login'
import Account from './components/Account'
import VerifyEmailForRegistration from './components/VerifyEmailForRegistration'
import RegistrationPending from './components/RegistrationPending'
import RegistrationFailed from './components/RegistrationFailed'
import RegistrationSuccess from './components/RegistrationSuccess'
import ForgotPassword from './components/ForgotPassword'
import './App.css'

const App = () => {
  const [user, setUser] = useState(null)
  const [bagItems, setBagItems] = useState(() => {
    return JSON.parse(localStorage.getItem('bagItems')) || []
  })
  const apiUrl = process.env.REACT_APP_BACKEND_API_URL

  useEffect(() => {
    const fetchAccount = async () => {
      const accessToken = localStorage.getItem('accessToken')

      try {
        const response = await fetch(`${apiUrl}/api/shopify/account`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          credentials: 'include',
        })

        const data = await response.json()

        if (response.ok) {
          setUser(data)
        } else {
          setUser(null)
          localStorage.removeItem('accessToken')
          localStorage.removeItem('tokenExpiry')
        }
      } catch (error) {
        console.error('Error fetching account:', error)
      }
    }

    fetchAccount()
  }, [apiUrl])

  useEffect(() => {
    localStorage.setItem('bagItems', JSON.stringify(bagItems))
  }, [bagItems])

  const addToBag = (product, selectedVariantId, selectedSize, quantity) => {
    if (!selectedVariantId) {
      console.error('Selected Variant ID is missing!')
      return
    }

    setBagItems((prevItems) => {
      const existingProductIndex = prevItems.findIndex(
        (item) =>
          item.variantId === selectedVariantId && item.size === selectedSize
      )

      if (existingProductIndex !== -1) {
        return prevItems.map((item, index) =>
          index === existingProductIndex
            ? { ...item, quantity: item.quantity + quantity }
            : item
        )
      } else {
        return [
          ...prevItems,
          {
            ...product,
            variantId: selectedVariantId,
            size: selectedSize,
            quantity,
          },
        ]
      }
    })
  }

  const updateQuantity = (variantId, size, newQuantity) => {
    setBagItems((prevItems) =>
      prevItems.map((item) =>
        item.variantId === variantId && item.size === size
          ? { ...item, quantity: newQuantity }
          : item
      )
    )
  }

  const removeFromBag = (variantId, size) => {
    setBagItems((prevItems) =>
      prevItems.filter(
        (item) => !(item.variantId === variantId && item.size === size)
      )
    )
  }

  const clearBag = () => {
    setBagItems([])
    localStorage.removeItem('bagItems')
  }

  return (
    <Router>
      <Nav bagItemCount={bagItems.length} user={user} />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <ProductList addToBag={addToBag} bagItems={bagItems} />
            </>
          }
        />
        <Route
          path="/bag"
          element={
            <Bag
              items={bagItems}
              updateQuantity={updateQuantity}
              removeFromBag={removeFromBag}
              clearBag={clearBag}
              user={user}
            />
          }
        />
        <Route path="/contact" element={<Contact />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/verification-success" element={<VerificationSuccess />} />
        <Route path="/verification-failed" element={<VerificationFailed />} />
        <Route path="/terms-conditions" element={<TermsAndConditions />} />
        <Route path="/return-policy" element={<ReturnPolicy />} />
        <Route path="/shipping-policy" element={<ShippingPolicy />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route
          path="/verify-registration"
          element={<VerifyEmailForRegistration />}
        />
        <Route path="/registration-pending" element={<RegistrationPending />} />
        <Route path="/registration-failed" element={<RegistrationFailed />} />
        <Route path="/registration-success" element={<RegistrationSuccess />} />
        <Route path="/login" element={<Login setUser={setUser} />} />
        <Route
          path="/account"
          element={<Account user={user} setUser={setUser} />}
        />
      </Routes>
      <Footer />
    </Router>
  )
}

export default App
