import React from 'react'
import './TermsAndConditions.css'

const TermsAndConditions = () => {
  return (
    <div className="terms-conditions">
      <div className="terms-header">
        <h1>Terms and Conditions</h1>
      </div>
      <section>
        <h2>1. Acceptance of Terms</h2>
        <p>
          By accessing or using our website, you agree to comply with these
          Terms and Conditions, as well as any applicable laws and regulations.
        </p>
      </section>

      <section>
        <h2>2. Products</h2>
        <p>
          We sell custom-printed t-shirts with personalized branding. Product
          images and descriptions are provided to give a general idea of what
          the product looks like, but we cannot guarantee exact color or design
          representation.
        </p>
      </section>

      <section>
        <h2>3. Order Processing</h2>
        <p>
          All orders are processed as soon as possible. However, we do not
          guarantee delivery dates. Orders are subject to availability and
          payment authorization. If we cannot fulfill an order, we will notify
          you and issue a refund.
        </p>
      </section>

      <section>
        <h2>4. Pricing</h2>
        <p>
          All prices are listed in USD and may be subject to change. Taxes and
          shipping fees will be added to your total at checkout.
        </p>
      </section>

      <section>
        <h2>5. Payment</h2>
        <p>
          We accept payments via Shopify Payments, which includes Visa,
          Mastercard, American Express, Diners Club, Apple Pay, Google Pay, and
          Shop Pay. Additional payment methods may be available depending on
          your region. You are responsible for any taxes or fees associated with
          your purchase.
        </p>
      </section>

      <section>
        <h2>6. Intellectual Property</h2>
        <p>
          All designs, trademarks, and content on this website are owned by
          L. E. Fent or our partners. You may not use, reproduce, or
          distribute any materials without prior written consent.
        </p>
      </section>

      <section>
        <h2>7. Limitation of Liability</h2>
        <p>
          We are not liable for any direct or indirect damage arising from the
          use or inability to use our products. Our maximum liability will not
          exceed the total value of your order.
        </p>
      </section>

      <section>
        <h2>8. Changes to Terms</h2>
        <p>
          We may update these Terms and Conditions at any time. You will be
          notified of any significant changes, and continued use of the website
          constitutes your acceptance of the revised terms.
        </p>
      </section>

      <section>
        <h2>9. Governing Law</h2>
        <p>
          These Terms and Conditions are governed by the laws of the State of Florida, United States. Any disputes will be resolved in the appropriate
          courts.
        </p>
      </section>
    </div>
  )
}

export default TermsAndConditions
