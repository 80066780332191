import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.css'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <ul className="footer-links">
          <li>
            <Link to="/terms-conditions">Terms & Conditions</Link>
          </li>
          <li>
            <Link to="/return-policy">Return Policy</Link>
          </li>
          <li>
            <Link to="/shipping-policy">Shipping Policy</Link>
          </li>
          <li>
            <Link to="/contact">Contact Us</Link>
          </li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer
