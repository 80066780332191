import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import './Register.css'

const Register = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
  })
  const [errors, setErrors] = useState({})
  const [isChecked, setIsChecked] = useState(false)
  const [loading, setLoading] = useState(false)
  const apiUrl = process.env.REACT_APP_BACKEND_API_URL
  const navigate = useNavigate()

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^()_+\-=[\]{};':"\\|,.<>/])[A-Za-z\d@$!%*?&#^()_+\-=[\]{};':"\\|,.<>/]{8,}$/
    return passwordRegex.test(password)
  }

  const validateName = (name) => {
    const nameRegex = /^[A-Za-z]{2,30}$/
    return nameRegex.test(name)
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
    setErrors({ ...errors, [e.target.name]: '' })
  }

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
    setErrors({ ...errors, checkbox: '' })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    let newErrors = {}

    if (!validateName(formData.firstName)) {
      newErrors.firstName =
        'First Name must contain only letters and be 2-30 characters long.'
    }
    if (!validateName(formData.lastName)) {
      newErrors.lastName =
        'Last Name must contain only letters and be 2-30 characters long.'
    }
    if (!validateEmail(formData.email)) {
      newErrors.email = 'Invalid email format'
    }
    if (!validatePassword(formData.password)) {
      newErrors.password =
        'Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character.'
    }
    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match'
    }
    if (!isChecked) {
      newErrors.checkbox = 'You must agree to the terms to register.'
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)
      setLoading(false)
      return
    }

    try {
      const response = await fetch(`${apiUrl}/api/shopify/register`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: formData.email,
          password: formData.password,
          firstName: formData.firstName,
          lastName: formData.lastName,
        }),
        credentials: 'include',
      })

      const data = await response.json()

      if (response.ok) {
        navigate('/registration-pending')
      } else {
        setErrors({
          general: data.error || 'Failed to register. Please try again.',
        })
      }
    } catch (error) {
      setErrors({ general: 'An error occurred. Please try again.' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="register-container">
      <h2 className="form-header">Register</h2>
      <form onSubmit={handleSubmit} className="register-form">
        <input
          name="firstName"
          placeholder="First Name"
          onChange={handleChange}
          className={errors.firstName ? 'error-input' : ''}
          required
        />
        {errors.firstName && <p className="error">{errors.firstName}</p>}
        <input
          name="lastName"
          placeholder="Last Name"
          onChange={handleChange}
          className={errors.lastName ? 'error-input' : ''}
          required
        />
        {errors.lastName && <p className="error">{errors.lastName}</p>}
        <input
          name="email"
          placeholder="Email"
          type="email"
          onChange={handleChange}
          className={errors.email ? 'error-input' : ''}
          required
        />
        {errors.email && <p className="error">{errors.email}</p>}
        <input
          name="password"
          placeholder="Password"
          type="password"
          onChange={handleChange}
          className={errors.password ? 'error-input' : ''}
          required
        />
        {errors.password && <p className="error">{errors.password}</p>}
        <input
          name="confirmPassword"
          placeholder="Confirm Password"
          type="password"
          onChange={handleChange}
          className={errors.confirmPassword ? 'error-input' : ''}
          required
        />
        {errors.confirmPassword && (
          <p className="error">{errors.confirmPassword}</p>
        )}

        <label className="checkbox-container">
          <span className="checkbox-star">*</span>
          <span>
            I agree to the{' '}
            <Link to="/terms-conditions">terms and conditions</Link>
          </span>
          <input
            type="checkbox"
            id="terms"
            checked={isChecked}
            onChange={handleCheckboxChange}
            className="checkbox-input"
          />
        </label>

        {errors.checkbox && <p className="error">{errors.checkbox}</p>}
        {errors.general && <p className="error">{errors.general}</p>}
        <button type="submit">{loading ? 'Registering...' : 'Register'}</button>
      </form>
      <div className="register-links">
        <p>
          Already have an account? <Link to="/login">Login</Link>
        </p>
      </div>
    </div>
  )
}

export default Register
