import './RegistrationSuccess.css'

const RegistrationSuccess = () => {
  return (
    <div className="registration-container">
      <h2 className="registration-success">Registration Successful!</h2>
      <p>
        Welcome to the store! You can now access your account and start
        shopping.
      </p>
    </div>
  )
}

export default RegistrationSuccess
